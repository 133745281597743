<template>
  <!-- 细分设备 -->
  <div class="device_unit">
    <el-form :model="ruleForm" ref="ruleForm" label-width="5px">
      <el-form-item prop="code">
        <el-select
          style="width:140px"
          v-model="ruleForm.code"
          :disabled="detailsID?true:false"
          @change="f_input"
        >
          <el-option
            v-for="(item, index) in dictData.device_type_dict"
            :key="index"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
const _ = require('lodash')
import {
  device_type,
  device_type_dict,
} from "./dictionaryData.js";
export default {
  components: {},
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    id:{
        type:String,
        default:''
    }
  },
  data() {
    return {
      ruleForm: {},
      dictData: {
        device_type,
        device_type_dict,
      },
    };
  },
  computed: {
    detailsID(){
      return this.id
    },
    ruleFormData() {
        // console.log(this.value,"设备传入的值")
      return _.cloneDeep(this.value);
    },
  },
  watch: {
    ruleFormData: {
      handler(newV) {
        this.f_inputParent()
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    // 初始化传过来的数据
    f_inputParent(){
      this.$nextTick(() => {
            //根据父元素传来的数据初始化表单数据
            let data = _.cloneDeep(this.ruleFormData)
            let leng = data.length,code=""
            console.log(code,"cide",data)
            switch(leng){
                case 1: 
                code = 'desktop'
                break; 
                case 2: 
                code = 'android'
                break; 
                case 3: 
                code = 'ios'
                break; 
                case 8: 
                code = 'mobile'
                break; 
                default:
                code = 'all'
            }
            // console.log(code,"cide----")
          this.$set(this.ruleForm,'code',code)
        });
    },
    // 结果传给父元素
    f_input(val) {
        // console.log(val,"val")
        let arr = []
        this.dictData.device_type_dict.forEach(item=>{
            if(item.value == val) arr = [...item.code]
        })
        this.$emit("input", arr);
    },
  },
};
</script>

<style lang="scss" scoped>
.device_unit {
  .el-select,
  .el-input {
    width: 130px;
  }
}
</style>