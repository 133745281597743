<template>
  <div class="gui-ze-son">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
        <el-row>
        <el-col :span="12">
            <div class="site">
                <el-form-item prop="field">
                    <el-select v-model="ruleForm.field" disabled placeholder="请选择" @change="f_input">
                        <el-option
                        v-for="item in fieldOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
        </el-col>
        <el-col :span="6">
            <div class="val">
                <el-form-item prop="operator" label-width="10px">
                    <el-select v-model="ruleForm.operator" placeholder="请选择" :disabled="detailsID?true:false" @change="f_input">
                        <el-option
                        v-for="item in operatorOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
        </el-col>
        </el-row>
        <el-row>
        <el-col :span="24">
            <div class="content">
                <el-form-item prop="value">
                    <el-select
                       :disabled="detailsID?true:false"
                        @change="f_input_value"
                        v-model="ruleForm.value"
                        multiple
                        filterable
                        allow-create
                        default-first-option
                        placeholder="以下值中的至少一项">
                        <el-option
                        v-for="(item,index) in valueOptions"
                        :key="index"
                        :label="item"
                        :value="index">
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
        </el-col>
        </el-row>
      </el-form>
  </div>
</template>

<script>
const _ = require('lodash')
export default {
  components: {},
  props: {
      value:{
          type:Array,
          default:()=>[]
      },
      id:{
        type:String,
        default:''
    }
  },
  data() {
    return {
        ruleForm:{value:[],field:"url",operator:"i_contains"},//本页面双向绑定的数据
        rules:{
           value: [
            { required: true, message: '请输入', trigger: ['change','blur'] }
          ]
        },
        fieldOptions:[{label:"网址",value:"url"}],//网址集合
        operatorOptions:[{label:"包含",value:"i_contains"},{label:"不包含",value:"i_not_contains"},{label:"等于",value:"eq"}],//规则集合
        valueOptions:[]
    };
  },
  computed: {
      detailsID(){
        return this.id
      },
      ruleFormData(){
        //   console.log(this.value,"value",)
          let obj = {field: "url", operator: "i_contains", value: []}
          if(this.value&&this.value.length>0){
            let data = this.value
            if(!(data.length==1&&data[0].value == "")){
                obj.field = data[0].field
                obj.operator = data[0].operator
                obj.value = data.map(item=>{
                    return item.value
                })
            }else{
                obj.field = data[0].field
                obj.operator = data[0].operator
                obj.value = []
            }
          } 
          return {...obj}
      },
  },
  watch: {
      "ruleFormData":{
        handler(){
            this.$nextTick(()=>{
                this.f_initParent()
            })
        },
        deep:true,
        immediate:true
      }
  },
  created() {},
  mounted() {
    // this.$bus.on("onCheck",()=>{
    //     this.f_save()
    // });
  },
  beforeDestroy() {
    // this.$bus.off("onCheck");
  },
  methods: {
    f_save(){
        this.$refs['ruleForm'].validate((valid) => {
          if (!valid) {
            return 
          }
        });
    },
    //根据父元素传来的数据初始化表单数据
    f_initParent(){
        this.ruleForm = {...this.ruleFormData}
    },
    // 结果传给父元素
    f_input(val){
        // console.log(val)
        if(val=='i_not_contains'&&this.ruleForm.value.length>1) this.ruleForm.value = [this.ruleForm.value[0]]
        let arr = []
        if(this.ruleForm.value&&this.ruleForm.value.length>0){
            this.ruleForm.value.forEach(item=>{
                let obj = {}
                obj.field = this.ruleForm.field
                obj.operator = this.ruleForm.operator
                obj.value = item
                arr.push(obj)
            })
        }else{
            arr.push({field:this.ruleForm.field,operator:this.ruleForm.operator,value:''})
        }
        this.$emit("input", arr);
    },
    // 不包含的时候输入框只能输入一个值
    f_input_value(){
        this.f_input(this.ruleForm.operator)
    },
  },
};
</script>

<style lang="scss" scoped>
.gui-ze-son {
    ::v-deep.el-form{
        .el-form-item{
            margin-bottom: 5px;
            .el-select,.el-input{
                width: 100%;
            }
            
        }
    }
}
</style>