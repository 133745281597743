import { render, staticRenderFns } from "./unitDevice.vue?vue&type=template&id=12cfdecd&scoped=true"
import script from "./unitDevice.vue?vue&type=script&lang=js"
export * from "./unitDevice.vue?vue&type=script&lang=js"
import style0 from "./unitDevice.vue?vue&type=style&index=0&id=12cfdecd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12cfdecd",
  null
  
)

export default component.exports