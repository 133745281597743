<template>
  <div class="unit-catagrory">
    <el-form :model="ruleForm" ref="ruleForm" label-width="10px">
      <!-- 像素 -->
      <el-row>
        <el-col :span="18">
          <div class="pixel">
            <el-form-item prop="event_sources">
                <el-select v-model="ruleForm.event_sources" :disabled="detailsID?true:false" placeholder="请选择" @change="f_input">
                    <el-option
                    v-for="item in pixelOptions"
                    :key="item.label"
                    :label="item.name"
                    :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <div class="contain">
        <!-- 选项 -->
        <el-row>
            <el-col :span="24">
                <div class="content">
                    <div class="choice">
                        <el-form-item prop="template">
                        <el-select v-model="ruleForm.template" placeholder="请选择" :disabled="detailsID?true:false" @change="f_changeTemplate">
                            <el-option
                                v-for="item in choiceOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                                >
                                </el-option>
                        </el-select>
                        </el-form-item>
                    </div>
                    <div class="percent" v-if="ruleForm.template == 'TOP_TIME_SPENDERS'">
                        <el-form-item prop="aggregation">
                            <el-select v-model="ruleForm.aggregation" :disabled="detailsID?true:false" placeholder="请选择" @change="f_input">
                                <el-option
                                v-for="(item,index) in percentOptions"
                                :key="index"
                                :label="item.name"
                                :value="item.name"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="text">过去</div>
                    <div class="date">
                        <el-form-item prop="retention_seconds" :rules="
                        [
                            { required: true,validator: validatePass,trigger:'blur'}
                        ]">
                        <el-input type="number"
                            :disabled="detailsID?true:false"
                            @change="f_input"
                            v-model.number="ruleForm.retention_seconds"
                            :min="1"
                            :max="180"
                        ></el-input>
                        <!-- <span>{{ruleForm.retention_seconds | timeToDate}}</span> -->
                        </el-form-item>
                    </div>
                    <div class="text">天</div>
                </div>
            </el-col>
        </el-row>
        <div class="chooseRules" v-if="ruleForm.template == 'TOP_TIME_SPENDERS'&&isShowTing" @click="f_thirdText">选择特定网页</div>
        <!-- 规则 -->
        <el-row v-if="ruleForm.template == 'VISITORS_BY_URL'||isShowThird">
            <el-col :span="24">
            <div class="cnt">
                <el-form-item v-for="(domain,index) in lastFilters" :key="index">
                    <gui-ze v-model="domain.filters" v-if="domain.filters" :id="detailsID" @input="f_small_union"></gui-ze>
                    <span class="gui_ze_close" @click="f_closeGuiZe(index)" v-if="detailsID?false:true">X</span>
                    <span class="tui_ze_tip chooseRules" style="cursor:default;" v-if="index<lastFilters.length-1">同一网址也必须匹配</span>
                </el-form-item>
            </div>
            </el-col>
        </el-row>
        <!-- 添加和 -->
        <div class="sum chooseRules" v-if="isShowSum&&(detailsID?false:true)"><span @click="f_addUnit">添加和</span></div>
      </div>
      <!-- 细分条件 -->
      <unit-detail-condition v-model="newAggregFilters" :id="detailsID" v-if="ruleForm.template == 'VISITORS_BY_URL'"  @input="f_getdetailscondition"></unit-detail-condition>
    </el-form>
  </div>
</template>

<script>
const _ = require('lodash')
import guiZe from "./unitSite.vue";
import unitDetailCondition from "./unitDetailCondition.vue"
import * as API from '@/api/customAudience.js'
export default {
  components: { guiZe,unitDetailCondition },
  props: {
      value:{
          type:Object,
          default:()=>{}
      },
      id:{
          type:String,
          default:''
      }
  },
  data() {
      var validatePass = (rule,value,callback) => {
          console.log(value);
          if (!value) {
            return callback(new Error("留存率不能为空"));
        } else {
            if (value / 1 < 1 || value / 1 > 180) {
            return callback(new Error("必须1-180之间"));
            } else {
            callback();
            }
        }
      }
    return {
      isShowTing:false,//按停留时间
      isShowThird:false,//按停留时间搜索访客的时候显示字体
      percentOptions:[{operator: "in_range", method: "percentile", type: "time_spent", value: {from: 95, to: 100},name:"最高的5%"},{operator: "in_range", method: "percentile", type: "time_spent", value: {from: 90, to: 100},name:"最高的10%"},{operator: "in_range", method: "percentile", type: "time_spent", value: {from: 75, to: 100},name:"最高的25%"}],
      pixelOptions: [],//[{id:3260379027391808, type: "pixel",name:"河南省顺风服饰有限公司的 Pixel 像素代码"},{id:399701384263724, type: "pixel",name:"JP_3.0's Pixel"},{id:1748891155258428, type: "pixel",name:"Kikitown0225-3的 Pixel 像素代码"}], //像素集合
      choiceOptions: [{id: "ALL_VISITORS", name:"所有网站访客"},{id: "VISITORS_BY_URL", name:"特定网页访客"},{id: "TOP_TIME_SPENDERS", name:"按停留时间搜索访客"}], //选择集合
      ruleForm: {aggregation:null,event_sources:'',template:'',},//所有的表单数据
      newAggregFilters:{},//取出来的细分条件数据
      lastFilters:[],//剩余的过滤好的数据
      validatePass:validatePass
    };
  },
//   filters:{
//         timeToDate(result){
//             return result&&result*24*3600;
//         }
//   },
  computed: {
      isShowSum(){
          if(this.lastFilters){
              let data = this.lastFilters,leng = this.lastFilters.length
            //   console.log(data,leng>0&&data[leng-1].filters&&data[leng-1].filters[0].value)
            //   this.f_input()
              return leng>0&&data[leng-1].filters&&this.f_isEmpty(data[leng-1].filters[0].value)?true:false
          }else{
              return false
          }
      },
      detailsID(){
          return this.id
      },
    //初始化数据初始值
      ruleFormData(){
          return _.cloneDeep(this.value)
      },   
  },
  watch: {
      "ruleFormData":{
        handler(){
            this.$nextTick(()=>{
                this.f_initParent()
            })
        },
        deep:true,
        immediate:true
      },
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    // 获取像素
    getAccountPixel() {
        let request = {
            accountId: this.$store.getters.accountId
        }
        API.accountPixel(request).then(res => {
            if (res.code === 0) {
                let data = res.data
                this.pixelOptions = data.map(v=> {
                    let item = {
                        name: v.name,
                        id: v.pixel_id,
                        is_unavailable: v.is_unavailable
                    }
                    return item
                })
            }
        })
    },
    //   细分结果传回来的值
    f_getdetailscondition(val){
        // console.log(val,"深度克隆")
        this.newAggregFilters = _.cloneDeep(val)
        this.$set(this.ruleForm,'aggregation',this.newAggregFilters.aggregation)
        this.f_small_union()
    },
    // 结果传给父元素
    f_input(){
        let data = _.cloneDeep(this.ruleForm)
        // 改变百分比  ---按停留时间搜索访客的情况下
        if(this.ruleForm.template == 'TOP_TIME_SPENDERS'){
            let aggregation = {}
            this.percentOptions.forEach(item=>{
                if(item.name==this.ruleForm.aggregation) aggregation = item
            })
            this.$set(data,'aggregation',aggregation)
        }
        // 改变时间
        this.$set(data,'retention_seconds',parseInt(this.ruleForm.retention_seconds)*24*3600)
        // 改变像素
        let obj = {}
        // console.log(this.ruleForm.event_sources,"this.ruleForm.event_sources")
        this.pixelOptions.forEach(item=>{
            if(item.id == this.ruleForm.event_sources) obj = item
        })
        // console.log(obj,"obj")
        this.$set(data,'event_sources',[obj])
        if(data.aggregation=='') data.aggregation = {}
        this.$emit("input", data);
    },
    //   初始化数据
    f_initParent(){
        this.$nextTick(async ()=>{
            let data = _.cloneDeep(this.value)
            this.ruleForm = {...data}
            // 初始化时间
            let s = data.retention_seconds
            this.$set(this.ruleForm,'retention_seconds',s/24/3600)
            // 初始化像素
            let id = ''
            // console.log(data.event_sources[0],"每一个")
            // 获取像素
            if (this.pixelOptions.length === 0) {
                let request = {
                    accountId: this.$store.getters.accountId
                }
                await API.accountPixel(request).then(res => {
                    if (res.code === 0) {
                        let data = res.data
                        this.pixelOptions = data.map(v=> {
                            let item = {
                                name: v.name,
                                id: v.pixel_id,
                                is_unavailable: v.is_unavailable
                            }
                            return item
                        })
                    }
                })
            }
            this.pixelOptions.forEach(item=>{
                if(data.event_sources[0].id==item.id) id = item.id
            })
            this.$set(this.ruleForm,'event_sources',id)
             //   如果是细分条件
             // 第二步  找出细分设备信息
            let filterDevice = data.filter?.filters,leng = data.filter?.filters.length,obj = {},arr = []
            if(leng>0){
                filterDevice.forEach(item=>{
                    if(item.filters&&item.filters.length>0){
                        if(item.filters[0].field == 'device_type') obj = item//设备的
                        else arr.push(item)//剩余filters的
                    }
                })
            }
            this.lastFilters = _.cloneDeep(arr)
            this.$set(this.newAggregFilters,'filters',obj)
            // console.log(this.ruleForm,"大表单的表单元素999999")
                //   第一步  重组aggregation
            if( data.aggregation && JSON.stringify(data.aggregation)!='{}' &&(data.aggregation.method=='absolute')) this.$set(this.newAggregFilters,'aggregation',data.aggregation)
            // else this.$set(this.newAggregFilters,'aggregation',{})
           
            
            // 初始化百分比--按停留时间搜索访客
            if( data.aggregation && JSON.stringify(data.aggregation)!='{}'&&(data?.aggregation?.method=="percentile")){
                let aggregation = data.aggregation
                this.percentOptions.forEach(item=>{
                    if(item.name==aggregation.name) this.$set(this.ruleForm,'aggregation',item.name)
                })
            }else{
                this.$set(this.ruleForm,'aggregation','')
            }
            
        })
    },
    //判断字符是否为空的方法
    f_isEmpty(obj){
        if(typeof obj == "undefined" || obj == null || obj == ""||obj == undefined){
            return false;
        }else{
            return true;
        }
    },
    //选择特定网页
    f_thirdText(){
        this.isShowThird = true
        this.f_addUnit()
        this.isShowTing = false
    },
    // 改变规则的方式
    f_changeTemplate(val){
        // console.log(val)
        this.ruleForm.aggregation = {}
        this.lastFilters = []
        this.newAggregFilters = {aggregation:{},filters:{}}
        if(val == 'VISITORS_BY_URL') {
            this.f_addUnit()
        }else{
            this.lastFilters.push({ field: "url", value: "",operator: "i_contains" })
        }
        if(val == 'TOP_TIME_SPENDERS') this.isShowTing = true
        else this.isShowTing = false

        this.f_small_union()
    },
    // 新增一个添加和单元格
    f_addUnit(){
        this.lastFilters.push({
            operator: "or",
            filters: [{ field: "url", value: "",operator: "i_contains" }],
            }
        )
    },
    // 最小规则单元格删除一个（内部单元格）
    f_closeGuiZe(index){
        if(this.lastFilters.length<=1&&this.ruleForm.template == 'VISITORS_BY_URL'||this.lastFilters.length<=1&&this.ruleForm.template == 'TOP_TIME_SPENDERS') return this.f_tips("已经是最后一个了！")
        this.lastFilters.splice(index,1)
        this.f_small_union()
    },
    f_small_union(){
        this.ruleForm.filter.filters = _.cloneDeep(this.lastFilters)
        if(JSON.stringify(this.newAggregFilters.filters) != "{}") {
            this.ruleForm.filter.filters.push(this.newAggregFilters.filters)
        }
        // console.log(this.ruleForm.filter.filters,"表单")
        this.f_input()
    },
    // 提示信息
    f_tips(val){
        this.$message({
            title: "提示",
            message: val,
            type: "warning",
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.unit-catagrory{
    width: 100%;
    ::v-deep.el-select,.el-input{
        width: 100%;
    }
    .contain{
        border: 1px solid rgb(218, 220, 222);
        border-radius: 5px;
        margin-left: 10px;
        padding-top: 10px;
        padding-right: 10px;
    }
    .content{
        display: flex;
        justify-content: flex-start;
        align-items:center;
        .text{
            margin-left: 10px;
            align-self: center;
            position: relative;
            top: -5px;
        }
        .choice{
            .el-select{
                width: 300px;
            }
        }
        .percent{
            .el-select{
                width: 130px;
            }
        }
        .date{
            .el-input{
                width: 100px;
            }
        }
    }
    ::v-deep.el-form-item{
        margin-bottom: 10px;
    }
    .chooseRules{
        cursor: pointer;
        margin-left: 15px;
        margin-bottom: 0px;
        color: rgb(64, 169, 255);
        font-size: 12px;
        font-weight: bold;
    }
    .sum{
        position: relative;
        top: -10px;
    }
    .cnt{
        position: relative;
        .gui_ze_close{
            position: absolute;
            top: 0;
            right: 10px;
            cursor: pointer;
        }
        .tui_ze_tip{
            margin-left: 5px;
            margin-bottom: 0;
            display: inline-block;
            line-height: 20px;
        }
    }
}
</style>