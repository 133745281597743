<template>
  <!-- 细分条件 -->
  <div class="detail-condition">
    <el-dropdown @command="f_command" :disabled="detailsID?true:false">
      <span class="el-dropdown-link">
        细分条件<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(item, index) in conditionOptions"
          :command="item.name"
          :key="index"
          :disabled="item.disabled"
          >{{ item.name }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <!-- 细分条件项 -->
    <div class="detail_title" v-if="commandType.includes('合计值')">
      细化条件：合计值
    </div>
    <el-form :model="ruleForm" ref="ruleForm" label-width="5px">
      <el-row>
        <el-col :span="24">
          <div class="contain_device" v-if="commandType.includes('合计值')">
            <div class="detail_option">
              <el-form-item prop=".aggregation.method">
                <el-select
                 :disabled="detailsID?true:false"
                  v-model="ruleForm.aggregation.method"
                  placeholder="请选择"
                  style="width"
                  @change="f_input"
                >
                  <el-option
                    v-for="item in dictData.device_type_method"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop=".aggregation.operator">
                <el-select
                  :disabled="detailsID?true:false"
                  v-model="ruleForm.aggregation.operator"
                  placeholder="请选择"
                  @change="f_input"
                >
                  <el-option
                    v-for="item in dictData.device_operator"
                    :key="item.operator"
                    :label="item.name"
                    :value="item.operator"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop=".aggregation.value">
                <el-input
                :disabled="detailsID?true:false"
                  type="number"
                  @change="f_input"
                  v-model.number="ruleForm.aggregation.value"
                ></el-input>
              </el-form-item>
              <el-form-item prop=".aggregation.type" class="details_hidden">
                <el-input
                :disabled="detailsID?true:false"
                  type="hidden"
                  @change="f_input"
                  v-model="ruleForm.aggregation.type"
                ></el-input>
              </el-form-item>
            </div>
            <span class="gui_ze_close" @click.stop.prevent="f_closeGuiZe('合计值')" v-if="detailsID?false:true">X</span>
          </div>
        </el-col>
      </el-row>
      <div
        class="detail_title detail_title_m"
        v-if="commandType.includes('设备')"
      >
        细化条件：设备
      </div>
      <el-row v-if="commandType.includes('设备')">
        <el-col :span="24">
          <div class="device">
            <!-- 设备 -->
            <el-form-item prop=".filters.filters">
              <unit-device
                v-model="ruleForm.filters.filters"
                @input="f_change_unit_device"
                :id="detailsID"
              ></unit-device>
              <span class="gui_ze_close" @click.stop.prevent="f_closeGuiZe('设备')" v-if="detailsID?false:true">X</span>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {
  device_operator,
  device_type_method,
} from "./dictionaryData.js";
import unitDevice from "./unitDevice.vue";
export default {
  components: { unitDevice },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    id:{
        type:String,
        default:''
    }
  },
  data() {
    return {
      ruleForm: {
        aggregation: {
          method: "absolute",
          type: "count",
          value: 2,
          operator: ">=",
        },
        // 同网址的一个小单元
        filters: {
          filters: [
            { field: "device_type", operator: "eq", value: "desktop" },
            {
              field: "device_type",
              operator: "eq",
              value: "mobile_android_phone",
            },
            {
              field: "device_type",
              operator: "eq",
              value: "mobile_android_tablet",
            },
            { field: "device_type", operator: "eq", value: "mobile_ipad" },
            { field: "device_type", operator: "eq", value: "mobile_iphone" },
            { field: "device_type", operator: "eq", value: "mobile_ipod" },
            { field: "device_type", operator: "eq", value: "mobile_phone" },
            { field: "device_type", operator: "eq", value: "mobile_tablet" },
            {
              field: "device_type",
              operator: "eq",
              value: "mobile_windows_phone",
            },
          ],
          operator: "or",
        },
      },
      commandType: [], //下拉项
      conditionOptions: [
        { name: "合计值", disabled: false },
        { name: "设备", disabled: false },
      ], //细分条件
      dictData: {
        device_operator,
        device_type_method,
      },
    };
  },
  computed: {
    ruleFormData() {
      return _.cloneDeep(this.value)
    },
    detailsID(){
        return this.id
    },
  },
  watch: {
    ruleFormData: {
      handler() {
        this.$nextTick(() => {
          this.f_initParent();
        });
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    // 初始化ruleForm数据
    f_initParent() {
      let data = _.cloneDeep(this.value)
      this.ruleForm = _.cloneDeep(this.value)
      // console.log("初始化了——————————————————",data)
      if (data.filters.filters && data.filters.filters.length > 0) this.f_handleCommand("设备");
      if (data.aggregation && data.aggregation.method &&data.aggregation.method == "absolute") this.f_handleCommand("合计值");
    },
    // 设备返回值
    f_change_unit_device(val) {
      // console.log(val, "这是设备返回值", this.ruleForm);
      if(val.length>0) this.$set(this.ruleForm,'filters',{operator: "or",filters:[...val]})
      else this.$set(this.ruleForm,'filters',{})
      this.$emit('input',this.ruleForm)
    },
    // 数据返回给大表单
    f_input(){
      // console.log(this.ruleForm,"细分条件项目所有条件")
      this.$emit('input',this.ruleForm)
    },
    
    f_handleCommand(command) {
      let arr = this.commandType;
      arr.push(command);
      this.commandType = [...new Set(arr)];
      // console.log(this.commandType, "-----",command);
      if (this.commandType.includes("设备")) this.conditionOptions[1].disabled = true;
      if (this.commandType.includes("合计值")) this.conditionOptions[0].disabled = true
        
    },
    // 下拉事件
    f_command(command){
      this.f_handleCommand(command)
      if (command == '合计值') this.$set(this.ruleForm,'aggregation',{method: "absolute",type: "count",value: 2,operator: ">=",})
      if (command == "设备") this.$set(this.ruleForm,'filters',{})
      this.$emit('input',this.ruleForm)
    },
    // 关闭细分条件
    f_closeGuiZe(text) {
      if (text == "合计值") {
        this.conditionOptions[0].disabled = false;
        this.$set(this.ruleForm,'aggregation',{})
      }
      if (text == "设备") {
        this.conditionOptions[1].disabled = false;
        this.$set(this.ruleForm,'filters',{})
      }
      if(this.commandType.includes(text)){
        let k = this.commandType.indexOf(text)
        this.commandType.splice(k,1)
      }
      this.$emit('input',this.ruleForm)
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-condition {
  ::v-deep.el-dropdown {
    margin-bottom: 0px;
  }
  ::v-deep.el-dropdown {
    margin-top: 0px;
    .el-dropdown-link {
      cursor: pointer;
      margin-top: 10px;
      color: rgb(64, 169, 255);
      font-size: 12px;
      font-weight: bold;
      margin-left: 15px;
    }
  }

  .detail_title {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0px;
    margin-left: 15px;
  }
  .detail_option {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin-left: 10px;
    ::v-deep.el-select,
    .el-input {
      width: 100px;
    }
    ::v-deep.details_hidden {
      width: 0;
      height: 0;
      z-index: -1;
    }
  }
  .device {
    position: relative;
  }
  .gui_ze_close {
    position: absolute;
    top: 0;
    right: 10px;
    cursor: pointer;
  }
  .contain_device {
    position: relative;
  }
}
</style>